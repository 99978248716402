import { assertNotNull } from '@/helpers/typing'
import { ab } from './interface'
import { Experiment } from './types'

import { runTextUpdates } from '@/services/ab/13448_ab_experiment'
import { Doc } from '@/models/interfaces'
import { Sentry } from '@/services/sentry'
import { IS_PRERENDERING } from '@/init/settings'
import Cookies from 'js-cookie'

/**
 * Initialize CloudFront experiments.
 *
 * Check A/B testing cookies set by CloudFront, send backend
 * requests and update frontend a/b data storage.
 */
export async function initAB(): Promise<void> {
  await newCopy13448Experiment(
    Experiment.new_copy_summary_v2_13448,
    '/summary/',
  )
  await newCopy13448Experiment(Experiment.new_copy_pdf_v2_13448, '/pdf')
  await aiGuideFlow13747()
}
/**
 * Feature flag to enable/disable Google Billing. Disabled by default.
 *
 * This is not a real A/B experiment, i.e. the experiment is not activated on
 * the backend.
 */
export function googleBillingEnabled(): boolean {
  return ab.experimentVariant(Experiment.google_billing_7517) === 'yes'
}

/**
 * Make 'View all' buttons more prominent
 */
export async function viewAllButton9162Experiment(): Promise<'A' | 'B' | 'C'> {
  let result = ab.experimentVariant(Experiment.view_all_button_9162)

  if (result === null) {
    result = await ab.start(Experiment.view_all_button_9162)
  }

  return (result || 'A') as 'A' | 'B' | 'C'
}

/**
 * Get the current `view_all_button_9162` experiment variant
 */
export function viewAllButton9162ExperimentVariant(): 'A' | 'B' | 'C' {
  const result = ab.experimentVariant(Experiment.view_all_button_9162)
  return (result || 'A') as 'A' | 'B' | 'C'
}

/**
 * Show (or don't show) the Podcasts item in the navigation and the block on search page
 * Returns boolean:
 *   true/excluded - show the podcast item in the header and the data on the search page
 *   false - don't the item in the header and the data on the search page
 */
export async function podcasts12992Experiment(): Promise<boolean> {
  let result = ab.experimentVariant(Experiment.podcasts_12992)

  if (result === null) {
    result = await ab.start(Experiment.podcasts_12992)
  }

  return result === 'yes'
}

/**
 * Show (or don't show) the Podcasts item in the navigation and the block on search page
 * Returns boolean:
 *   true/excluded - show the podcast item in the header and the data on the search page
 *   false - don't the item in the header and the data on the search page
 */
export function podcasts12992ExperimentVariant(): boolean {
  const result = ab.experimentVariant(Experiment.podcasts_12992)
  return result === 'yes' || result === '_EXCLUDED_'
}

/**
 * Get the variant of the LibraryV1_1 experiment.
 * Returns boolean:
 *   true - Show the LibraryV1_1.vue component
 *   false - Show the Library.vue component
 */
export function libraryV1_1Experiment12817Variant(): boolean {
  return ab.experimentVariant(Experiment.library_v1_1_12817) === 'yes'
}

/**
 * Decide which component to render on the /app/library page.
 * Returns boolean:
 *   true - Show the LibraryV1_1.vue component
 *   false - Show the Library.vue component
 */
export async function libraryV1_1Experiment12817(): Promise<boolean> {
  let result = ab.experimentVariant(Experiment.library_v1_1_12817)

  if (result === null) {
    result = await ab.start(Experiment.library_v1_1_12817)
  }

  return result === 'yes'
}

/**
 * Apply the text changes on the public pages
 *
 * @param {Experiment} experiment - The A/B experiment containing the text changes.
 * @param {string} allowedUrlExpression - A regular expression to match allowed URLs,
 * on which the text changes should be applied.
 */
export async function newCopy13448Experiment(
  experiment: Experiment,
  allowedUrlExpression: string,
): Promise<void> {
  if (!window.location.pathname.includes(allowedUrlExpression)) {
    // Only start experiment on the right public page
    return
  }

  let result = ab.experimentVariant(experiment)

  if (result === null) {
    result = await ab.start(experiment)
  }

  if (result === 'yes') {
    runTextUpdates(experiment)
  }
}

/**
 * Get the variant of the ai_label_13537 experiment.
 * Returns boolean:
 *   true - Show the ai label component
 *   false - Don't show the ai label component
 */
export function aiLabel13537ExperimentVariant(): boolean {
  return ab.experimentVariant(Experiment.ai_label_13537) === 'yes'
}

/**
 * Decide wheter or not to render the ai content label component in
 * the book preview and summary pages.
 *   true - Show the ai label component
 *   false - Don't show the ai label component
 */
export async function aiLabel13537Experiment(): Promise<boolean> {
  let result = ab.experimentVariant(Experiment.ai_label_13537)

  if (result === null) {
    result = await ab.start(Experiment.ai_label_13537)
  }

  return result === 'yes'
}

/**
 * Get the variant of the plans_page_expanded_value_prop_list_13530 experiment.
 * Returns boolean:
 *   true - Display the component with the expanded prop list
 *   false - Don't display the component with the expanded prop list
 */
export function plansPageExpandedValuePropList13530ExperimentVariant(): boolean {
  return (
    ab.experimentVariant(
      Experiment.plans_page_expanded_value_prop_list_13530,
    ) === 'yes'
  )
}

/**
 * Decide which plan card component to render on the billing page.
 * Returns boolean:
 *   true - Display the component with the expanded prop list
 *   false - Don't display the component with the expanded prop list
 */
export async function plansPageExpandedValuePropList13530Experiment(): Promise<boolean> {
  let result = ab.experimentVariant(
    Experiment.plans_page_expanded_value_prop_list_13530,
  )

  if (result === null) {
    result = await ab.start(
      Experiment.plans_page_expanded_value_prop_list_13530,
    )
  }

  return result === 'yes'
}
/*
 * Show the human book cover or the fallback on public summary pages:
 - A: Show human book cover
 - B: Show fall back book cover

 Within the main app we will only apply the experiment variant to one book (the one of the visited summary page).
 */
export async function aiCover13860Experiment(): Promise<void> {
  // Don't run the experiment while generating the static pages
  if (IS_PRERENDERING) {
    return
  }

  const humanCover = document.querySelector('.book-cover__image')
  const fallbackCover = assertNotNull(document.querySelector('.fallback-cover'))

  if (!humanCover) {
    // Don't start the experiment if we don't have a human cover
    fallbackCover.classList.remove('opacity_cover_13860')
    return
  }

  // Start the experiment, get the variant ("A" - human cover, "B" - automatic)
  let experimentVariant = ab.experimentVariant(Experiment.ai_cover_flow_13860)

  if (experimentVariant === null) {
    experimentVariant = await ab.start(Experiment.ai_cover_flow_13860)
  }

  if (experimentVariant === 'B') {
    // Hide human cover

    humanCover.classList.add('hide_cover_13860')

    // Show fallback cover
    fallbackCover.classList.remove('hide_cover_13860')
  }

  humanCover.classList.remove('opacity_cover_13860')
  fallbackCover.classList.remove('opacity_cover_13860')
}

/*
 * Check if we should show the fallback cover as part of the experiment ai_cover_flow_13860
 */
export function useFallbackCover_13860(doc: Doc): boolean {
  const experimentVariant = ab.experimentVariant(Experiment.ai_cover_flow_13860)
  return experimentVariant === 'B' && doc.is_first_doc
}

/*
 * Send a sentry message if we have an unexpected case.
 * The user is in the experiment, this is experiment book, but we do not have human cover.
 * In this case the book should not be in the experiment.
 */
export function checkCoverFlow_13860(doc: Doc): void {
  const experimentVariant = ab.experimentVariant(Experiment.ai_cover_flow_13860)

  if (experimentVariant !== null && doc.is_first_doc && !doc.cover_image) {
    Sentry.captureMessage(
      `Unexpected case in experiment ${Experiment.ai_cover_flow_13860}: Book [${doc.url_slug}] doesn't have a cover but is in the experiment.`,
    )
  }
}

/**
 * Toggle the Link integration with Stripe.
 * Returns boolean:
 *   true - Enable Stripe Link
 *   false - Disable Stripe Link
 */
export async function stripeLinkIntegration13752Experiment(): Promise<boolean> {
  let result = ab.experimentVariant(Experiment.stripe_link_integration_13752)

  if (result === null) {
    result = await ab.start(Experiment.stripe_link_integration_13752)
  }

  return result === 'yes'
}

/*
 * Read experiment cookies set by CloudFront and transfer the experiment assignment to the local storage
 */
export async function aiGuideFlow13747(): Promise<void> {
  const cfVariant = Cookies.get('X-AB-AI-Guide-Flow-13747')
  const localVariant = ab.experimentVariant(Experiment.ai_guide_flow_13747)

  if (cfVariant && !localVariant) {
    await ab.start(Experiment.ai_guide_flow_13747, cfVariant)
  }
}
